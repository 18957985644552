//global variables


export const config = {
    // backendURL : "https://portal.jilipesasa.com",
    // keycloackURL : "https://sso.jilipesasa.com:8443",
    // realm: "EWA"
    backendURL : "https://portal-uat.jilipesasa.com",
    keycloackURL : "https://sso.jilipesasa.com:8443",
    realm: "Sipranda"
    //backendURL : 'http://localhost:9010',
    //keycloackURL : 'http://172.17.0.3:8080'
}